<script>
import Layout from "../../layouts/horizontal";
import { mapGetters, mapActions } from 'vuex';
import PageHeader from "@/components/page-header";
import http from '@/explorer-client'
import {Chart} from 'highcharts-vue'
import { debounce } from "debounce"
import moment from 'moment'
import httpFxHedge from '@/service-clients/fx-hedge'
import { camelCase } from 'lodash';

import { mapState } from 'vuex';
import { uuid } from 'vue-uuid';


const camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

const createDefaultPortfolioSettings = () => ({
  HoldingAssetRiskToleranceMax: 70,
  HoldingAssetTarget: 50,
  HoldingAssetRiskToleranceMin: 30,
  HoldingLiabilitiesRiskToleranceMax: -30,
  HoldingLiabilitiesTarget: -50,
  HoldingLiabilitiesRiskToleranceMin: -70,
  OverallPortfolioLimits: false
});


const _getCurrencyRiskFactor = (curr) => {
  if(curr === 'USD') return	1;
  if(curr === 'EUR') return	1;
  if(curr === 'GBP') return	1;
  if(curr === 'JPY') return	1;
  if(curr === 'CHF') return	1;
  if(curr === 'CAD') return	1;
  if(curr === 'AUD') return	1;
  if(curr === 'NZD') return	1;
  if(curr === 'SEK') return	1;
  if(curr === 'NOK') return	1;
  if(curr === 'CNY') return	2;
  if(curr === 'BRL') return	2;
  if(curr === 'MXN') return	2;
  if(curr === 'ZAR') return	2;
  return 0;
};

const colors = [
'#3366CC',
'#DC3912',
'#FF9900',
'#109618',
'#990099',
'#3B3EAC',
'#0099C6',
'#DD4477',
'#66AA00',
'#B82E2E',
'#316395',
'#994499',
'#22AA99',
'#AAAA11',
'#6633CC',
'#E67300',
'#8B0707',
'#329262',
'#5574A6',
'#3B3EAC'
];

export default {
  components: {
    Layout,
    PageHeader,
    Highcharts:Chart
  },
  created() {
    this.load();
  },
  data() {
    return {
      title: "C8 Hedge ",
      chartKey: uuid.v1(),
      selectedDateIndex: 0,
      dates: [],
      formatter: val => {
        const date = this.dates[val];
        const label = moment(date).format('L');
        let res = `${label}\n\r`;
       // if(this.detailsDat?.Signal !== undefined)
        res += 'Signal: ' + this.detailsData.Signal + '\n\r';
        res += 'Ratio: ' + this.detailsData.Ratio + '%\n\r';
        this.fxHedgeData.ExplonationFactors.forEach((factorName, index) => {
          if(index > 0) res += '\n\r';
          res += factorName + ": " + this.detailsData.Factors[index];
        });
        return res;
     },
      dateRangeEnabled: false,
      isLoadCurrencies: false,
      isLoadUser: false,
      isSaveUser: false,
      currentUser: null,
      commodities: [],
      recalculating: false,
      defaultCurrency: null,
      hedgeBenchmark: 50,
      liability: false,
      currencyPortfolioSettings: createDefaultPortfolioSettings(),
      riskTolerance: {
        min:0,
        max:100,
        step:10
      },
      weight: 0,
      riskFactor: null,
      ready: false,
      currencies: [],
      fxHedgeData: null,
      breadcrumbs: [
      ],
      secondColor: "#6183e8",
      chartOptions: {
        series: [
          {
            type: 'line',
            data: [1, 2, 3]
          },
          
        ]
      },
      statistic: {
        
      },
      
      monthlyPerf: null,
      monthlyPerfRowNames:[],
      monthlyPerfColumnNames:[],
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "YTD"
      ],
    };
  },
  props: ['target', 'desc', 'id'],
  computed:{
    ...mapState('signalr', {
       connectionId: 'connectionId'
    }),
    loading() {
      return this.isLoadCurrencies && this.isLoadUser;
    },
    ...mapGetters('execution', {
     
    }),
    selectedDate() {
      return this.dates[this.selectedDateIndex];
    },
    detailsData() {
      if(this.selectedDate) {
        const key = this.dateKeyMap[this.selectedDate];
        const signal = this.fxHedgeData.Signal[key];
        const ratio = this.fxHedgeData.HedgeRatio[key];
        const factors = this.fxHedgeData.Factors[key] || [];
        return {
          Signal: signal,
          Ratio: ratio,
          Factors: factors
        }
      }
      return {};
    },
    isdAdmin(){
      return this.$store.state.auth?.currentUser?.isAdmin ?? false;
    }
  },
  watch: {
    connectionId: {
      handler: function () {
        this.load();
      }
    }
  },
  methods: {
    ...mapActions('execution', {
      loadFxHedgeDetails: 'getFxHedgeDetails',
      loadFxHedgePortfolioDetails: 'getFxHedgePortfolioDetails'
    }),
    debounceReload: debounce(function () {
      this.reload();
    }, 1500),
    async reload() {
      this.onRecalculate();
    },
    resetDateRange() {
      this.dateRangeEnabled = false;
      this.minFormatted = null;
      this.maxFormatted = null;
      // this.chartOptions.xAxis.min = null; // Reset min date
      // this.chartOptions.xAxis.max = null; // Reset max date
      this.load();
    },
    onAfterSetExtremes(e) {
      if(this.ready) {
        const min =  moment(e.min);
        const max =  moment(e.max);
        const minFormatted = min.format('YYYY-MM-DD');
        const maxFormatted = max.format('YYYY-MM-DD');
        
        this.minFormatted = minFormatted;
        this.maxFormatted = maxFormatted;

        this.dateRangeEnabled = true;

        this.debounceReload();
      }
    },
    async onRecalculate() {
      if(!this.connectionId || !this.currentUser) return;

      this.recalculating = true;
     
      try {
        let response = null;
        if(this.portfolioReqPayload) {
          const p = {
            ...this.portfolioReqPayload,
            minDate: this.minFormatted,
            maxDate: this.maxFormatted,
            riskFactorMethod: this.currencyPortfolioSettings.RiskFactor
          };
        
          response = await this.loadFxHedgePortfolioDetails(p);
        } else {
          if(this.desc === 'CURRENCIES') {
            const payload = {
              userId: this.currentUser.Id,
              roles: [...this.currentUser.Roles],
              defaultCurrency: this.defaultCurrency.Name,
              riskTolerance:{
                min: this.riskTolerance.min,
                mid: this.riskTolerance.mid,
                max: this.riskTolerance.max,
                step: (this.riskTolerance.step === null || this.riskTolerance.step === undefined) ? 10 : this.riskTolerance.step,
                maxChanges: this.riskTolerance.maxChanges
              },
              weight: this.weight || 0,
              riskFactor: (this.riskFactor == null || this.riskFactor == undefined) ? _getCurrencyRiskFactor(this.defaultCurrency.Name) : this.riskFactor,
              
              currencyPortfolioSettings: camelizeKeys(this.currencyPortfolioSettings),
              minDate: this.minFormatted,
              maxDate: this.maxFormatted,
              riskFactorMethod: this.currencyPortfolioSettings.RiskFactor,
              currency: this.target,
              benchmarkHedge:  this.hedgeBenchmark,
              liability: this.liability || false,
              currencySettings: this.currencySettings.map(c => ({
                ...c,
                currency: c.currency.Name,
              }))
            };
            
            response = await this.loadFxHedgeDetails(payload);
          } else {
            const commodity = this.commodities.find(x => x.product === this.target);
            const payload = {
              userId: this.currentUser.Id,
              roles: [...this.currentUser.Roles],
              defaultCurrency: this.target,
             
              riskTolerance:{
                min: commodity.riskTolerance.min,
                mid: commodity.riskTolerance.mid,
                max: commodity.riskTolerance.max,
                step: (commodity.riskTolerance.step === null || commodity.riskTolerance.step === undefined) ? 10 : commodity.riskTolerance.step,
                maxChanges: commodity.riskTolerance.maxChanges
              },
              weight: commodity.weight || 0,
              minDate: this.minFormatted,
              maxDate: this.maxFormatted,
              riskFactorMethod: this.currencyPortfolioSettings.RiskFactor,
              currency: this.target,
              benchmarkHedge:  commodity.hedgeBenchmark,
              liability: commodity.liability || false,

            };
            
            response = await this.loadFxHedgeDetails(payload);
          }
        }
       
       
        this.fxHedgeData = response;
        let dates = [];
        let dateKeyMap = {};
        if(response.Signal) {
          const keys = Object.keys(response.Signal);
          keys.sort((a, b) => a.localeCompare(b));
          keys.forEach(key => {
            const date = moment(key).toDate();
            dateKeyMap[date] = key;
            dates.push(date);
          });
        }

        // console.log('dates', dates);
        // console.log('dateKeyMap', dateKeyMap);
        
        if(dates.length > 0) {
          this.dates = dates;
          this.dateKeyMap = dateKeyMap;
        }
        this.statistic = response.SaveMainStatistic;
        
        //if(!this.ready) {

          let seriesOptions = [];
          const benchmarkPerformence = {
            name: 'Static Hedge',
            label: 'Static Hedge',
            data: (response.BenchMarkPerformence?.Points || []).map(x => [x.TimeStamp, x.Value]),
          // color: '#3366CC',
            lineWidth: 4
          };
          console.log('benchmarkPerformence', benchmarkPerformence);
          const hedgePerformence = {
            name: 'Dynamic Hedge',
            label: 'Dynamic Hedge',
            data: (response.HedgePerformence?.Points || []).map(x => [x.TimeStamp, x.Value]),
          // color: '#DC3912',
            lineWidth: 4
          };
          console.log('hedgePerformence', hedgePerformence);
          seriesOptions.push(benchmarkPerformence);
          seriesOptions.push(hedgePerformence);
          const self = this;
          this.chartOptions = {
            credits:{
              enabled: false
            },
            legend:{
              enabled: true
            },
            exporting: {
              buttons: {
                  contextButton: {
                      menuItems: [
                      "viewFullscreen", 
                      "printChart", 
                      "separator", 
                      "downloadPNG", 
                      "downloadJPEG", 
                      "downloadPDF", 
                      "downloadSVG",
                      "separator",
                      "downloadXLS",
                      "downloadCSV",
                      "viewData" ]
                  }
              }
            },
            rangeSelector: {
              buttons: [{
                  type: 'month',
                  count: 1,
                  text: '1m',
                  title: 'View 1 month'
              }, {
                  type: 'month',
                  count: 3,
                  text: '3m',
                  title: 'View 3 months'
              }, {
                  type: 'month',
                  count: 6,
                  text: '6m',
                  title: 'View 6 months'
              }, {
                  type: 'ytd',
                  text: 'YTD',
                  title: 'View year to date'
              }, {
                  type: 'year',
                  count: 1,
                  text: '1y',
                  title: 'View 1 year'
              }, 
              {
                  type: 'year',
                  count: 2,
                  text: '2y',
                  title: 'View 2 years'
              }, 
              {
                  type: 'year',
                  count: 3,
                  text: '3y',
                  title: 'View 3 years'
              }, 
              {
                  type: 'year',
                  count: 5,
                  text: '5y',
                  title: 'View 5 years'
              }, 
              {
                  type: 'all',
                  text: 'All',
                  title: 'View all'
              }]
            },
            xAxis: {
              type: 'datetime',
            
              labels: {
                  
              },

              events: {
                afterSetExtremes: this.onAfterSetExtremes,
              },
            
              min: null,
              max: null
            },
            yAxis: {
              type: null,
              labels: {
                formatter: function () {
                  return (this.value * 100) + '%';
                }
              },
              plotLines: [{
                value: 0,
                  width: 2,
                  color: 'silver'
              }]
            },
            plotOptions: {
              series: {
                showInNavigator: true
              }
            },
            tooltip: {
              useHTML: true,
              shared: true,
              formatter: function () {
                const { points } = this; 
                const date =  moment(this.x);
                //const dateTime = date.toDate();
                let formattedDate = date.format('L');
                let key = date.format('YYYY-MM-DD') + 'T00:00:00';
              
                if(!self.fxHedgeData.Signal || !self.fxHedgeData.HedgeRatio || !self.fxHedgeData.Factors )
                  return '';

  
                const signal = self.fxHedgeData.Signal[key];
                const ratio = self.fxHedgeData.HedgeRatio[key];
                const factors = self.fxHedgeData.Factors[key] || [];
               
                const res = [];
              
                res.push(formattedDate);

                points.forEach(point => {
                  const { series, y } = point;

                  const yValue = parseFloat((y * 100).toFixed(2)) + '%';
                  let pointHtml = `<div style='min-width:250px'>`;
                  pointHtml += `<div  class='d-flex justify-content-between'><h6 class='mr-4' style='color: ${point.color}'>${series.name}</h6><b class='text-end'>${yValue}</b></div>`;
                  
                  if(series.name === 'Dynamic Hedge' && signal != null &&  signal != undefined) {
                    pointHtml += `<dl class='row'>`;
                    pointHtml += `<dt class='col-8' style='font-weight:normal; margin-bottom:0px'>Recommended Hedge Ratio</dt><dd class='col-4 text-end' style='font-weight:normal; margin-bottom:0px'><b class=' text-end float-right'>${self.roundIfNumber(ratio)} %</b></dd>`;
                    pointHtml += '</dl>';

                    //pointHtml += '<div>Key drivers</div>';

                   // pointHtml += `<dl>`;
                    self.fxHedgeData.ExplonationFactors.forEach((factor, i) => {
                      if(factor !== 'FX Drivers' && factor !== 'Drivers' && factor !== 'Drivers for Hedge Ratio') {
                       // pointHtml += `<dt >Recommended Hedge Ratio</dt><dd >1</dd>`;
                        pointHtml += `<div class='d-flex justify-content-between'><div>${factor}</div><div  class=' text-end'><b class=' text-end float-right'>${self.roundIfNumber(factors[i])}</b></div></div>`;
                        //pointHtml += `<div style='font-weight:normal; margin-bottom:0px'>${factor}</div>`;
                        //pointHtml += `<div  class=' text-end'><b class=' text-end float-right'>${self.roundIfNumber(factors[i])}</b></div>`;
                        pointHtml += `</div>`;
                      }
                    });
                  }
                  //pointHtml += '</dl>';

                  pointHtml += `</div>`;
                  
                  res.push(pointHtml);
                });
               
                return res;
              },
              pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
              valueDecimals: 2,
              xDateFormat:  '%d/%m/%Y'
            },
            colors: colors,
            series: seriesOptions
          };
          this.chartKey = uuid.v1();
        //}
        this.buildMonthlyPerformanceTable();
      } catch(e) {
        console.error(e);
      } finally {
        this.recalculating = false;
      }
    },
    buildMonthlyPerformanceTable() {
      let mp = {};
      let monthlyPerfRowNames = [...new Set((this.fxHedgeData.MonthlyPerformanceTable || []).map(x => x.Year.toString()))];
      let monthlyPerfColumnNames = [...new Set((this.fxHedgeData.MonthlyPerformanceTable || []).map(x => this.monthNames[x.Month-1]))];
      monthlyPerfRowNames.sort((a, b) => a.localeCompare(b));
      monthlyPerfRowNames.reverse();
     
      monthlyPerfRowNames.forEach(rowName =>{
        const cells = this.fxHedgeData.MonthlyPerformanceTable.filter(x => x.Year.toString() === rowName);
        let a = {};
        mp[rowName] = a;
        monthlyPerfColumnNames.forEach(columnName =>{
          const cellItems = cells.filter(x =>  this.monthNames[x.Month-1] === columnName) || [];
          const c8Value = cellItems.find(x => x.Name === 'C8' || x.Name === (this.fxHedgeData || {}).Name) || {};
          const notC8Value = cellItems.find(x => x.Name !== 'C8' && x.Name !== (this.fxHedgeData || {}).Name) || {};
          a[columnName] = {
            base: c8Value.Value,
            additional: notC8Value.Value
          };
        });
      });
      this.monthlyPerf = mp;
      this.monthlyPerfRowNames = monthlyPerfRowNames;
      this.monthlyPerfColumnNames = monthlyPerfColumnNames;
    },
    async load() {
      await this.loadCurrencies()
      await this.loadUser();
      await this.onRecalculate();

      window.setTimeout(() =>{
        this.$nextTick(() => {
          this.ready = true;
        });
      }, 1000);
      
      if(this.desc === 'CURRENCIES') {
        if(this.target === 'Portfolio') {
          this.title = "Your currency " + this.defaultCurrency.Name + " vs Hedge Portfolio";
        } else {
          if(this.defaultCurrency) {
            if(this.liability) {
              this.title = "Your currency " + this.defaultCurrency.Name + " vs Having Liabilities in " + this.target;
            } else {
              this.title = "Your currency " + this.defaultCurrency.Name + " vs Holding Assets in " + this.target;
            }
          } else {
            if(this.liability) {
              this.title = "Having Liabilities in " + this.target;
            } else {
              this.title = "Holding Assets in " + this.target;
            }
          }
        }
        
      } else {
        console.log('load', {a: this.target, b:this.commodities });
        const commodity = this.commodities.find(x => x.product === this.target);
        if(commodity) {
          this.title = commodity.liability ? `Having Liabilities in ${commodity.product}` : `Holding Assets in ${commodity.product}`;
        } else {
          this.title = 'Portfolio';
        }
     
      }
    },
    async loadCurrencies()  {
      this.isLoadCurrencies = true;
      try {
        const resp = await http.get(`data/Currency/objects`, {
          $orderby: 'Name asc'
        });
        this.currencies = resp.data.Items;
      }
      finally {
        this.isLoadCurrencies = false;
      }
    },
    roundIfNumber(value) {
      if(value) {
        if (typeof value === 'number' || (typeof value === 'string' && !isNaN(value))) {
          let numberValue = parseFloat(value);
          return Math.round(numberValue * 100) / 100;
        }
      }
     
      return value;
    },
    async loadUser()  {
      this.isLoadUser = true;
      try {
        const respCurrentUser = await http.get(`data/User/objects/current`, {
          $orderby: 'Order asc'
        });
        this.currentUser = respCurrentUser.data;

        const resp = await httpFxHedge.get(`user-data`);
        const portfolio = resp.data.find(x => x.Id === this.id);
        const usd = this.currencies.find(x => x.Name === 'USD');
        const { BaseCurrency, KeyCcyExposures, CurrencyConfigs, AdditionalData, CurrencyPortfolioSettings } = portfolio;
        
        const additionalData = AdditionalData.find(x => x.Discriminator === 'COMMODITIES');
        this.commodities = additionalData.CurrencyConfigs.map(x => ({
          product: x.Currency,
          riskTolerance: {
            min: x.RiskToleranceMin,
            mid: x.RiskToleranceMid,
            max: x.RiskToleranceMax,
            maxChanges: (x.RiskToleranceMaxChanges == null || x.RiskToleranceMaxChanges == undefined || x.RiskToleranceMaxChanges === '') ? 100 :  x.RiskToleranceMaxChanges,
            step: (x.RiskToleranceStep === null || x.RiskToleranceStep === undefined) ? 10 : x.RiskToleranceStep,
          },
          hedgeBenchmark: x.Benchmark,
          liability: x.Liability,
          weight: x.Weight
        }))
        this.currencyPortfolioSettings =CurrencyPortfolioSettings;
        this.defaultCurrency = this.currencies.find(x => x.Name === BaseCurrency) || usd;
        const ccyExposures = [];
        if(KeyCcyExposures) {
          KeyCcyExposures.forEach(currency => {
            const c = this.currencies.find(x => x.Name === currency);
            if(c) {
              ccyExposures.push(c);
            }
          })
        }
        if(ccyExposures.length === 0) {
          ccyExposures.push(this.defaultCurrency);
        }
        if(this.desc === 'CURRENCIES') {
          this.currencySettings = [];
          ccyExposures.forEach(curr => {
            const conf = CurrencyConfigs.find(x => x.Currency === curr.Name);
            const s = {
              currency: curr,
              collapsed: true,
              hedgeBenchmark: conf?.Benchmark,
              liability: conf?.Liability || false,
              riskTolerance: {
                min: conf?.RiskToleranceMin,
                mid: conf?.RiskToleranceMid,
                max: conf?.RiskToleranceMax,
                step: (conf?.RiskToleranceStep === null || conf?.RiskToleranceStep === undefined) ? 10 : conf?.RiskToleranceStep,
                maxChanges: (conf?.RiskToleranceMaxChanges == null || conf?.RiskToleranceMaxChanges == undefined || conf?.RiskToleranceMaxChanges === '') ? 100 : conf?.RiskToleranceMaxChanges,
              },
              weight: conf?.Weight || 0,
              riskFactor: (conf?.RiskFactor == null || conf?.RiskFactor == undefined) ? _getCurrencyRiskFactor(curr.Name) : conf?.RiskFactor
            };
            this.currencySettings.push(s);
          });
        }
        if(this.target === 'Portfolio') {
          if(this.desc === 'CURRENCIES') {
            const portfolioReqPayload = {
              userId: this.currentUser.Id,
              roles: [...this.currentUser.Roles || []],
              defaultCurrency: this.defaultCurrency?.Name || null,
              ccyExposures: ccyExposures.map(x => x.Name),
              currencyPortfolioSettings: camelizeKeys(this.currencyPortfolioSettings),
              minDate: this.minFormatted,
              maxDate: this.maxFormatted,
              currencySettings: this.currencySettings.map(c => ({
                ...c,
                currency: c.currency.Name,

              }))
            
              // riskTolerance:this.riskTolerance,
              // hedgeBenchmark:  this.hedgeBenchmark
            };
            this.portfolioReqPayload = portfolioReqPayload;
            console.log('Portfolio payload', portfolioReqPayload);
          } else  {
            const portfolioReqPayload = {
              userId: this.currentUser.Id,
              roles: [...this.currentUser.Roles],
              defaultCurrency: this.target,
              products: this.commodities.map(x => x.product),
              minDate: this.minFormatted,
              maxDate: this.maxFormatted,
              currencySettings:this.commodities.map(x => ({
                ...x,
                currency: x.product
              })),
            };
            this.portfolioReqPayload = portfolioReqPayload;
            console.log('Portfolio payload', portfolioReqPayload);
          }
        } else {
          const currConfig = CurrencyConfigs.find(x => x.Currency == this.target);
          this.ccyExposures = ccyExposures;
          this.hedgeBenchmark = currConfig?.Benchmark ?? 50;
          this.liability = currConfig?.Liability ?? false;
          this.riskTolerance.min = currConfig?.RiskToleranceMin ?? 0;
          this.riskTolerance.mid = currConfig?.RiskToleranceMid ?? 50;
          this.riskTolerance.max = currConfig?.RiskToleranceMax ?? 100;
          this.riskTolerance.step = (currConfig?.RiskToleranceStep === null || currConfig?.RiskToleranceStep === undefined) ? 10 : currConfig?.RiskToleranceStep,
          this.riskTolerance.maxChanges = (currConfig?.RiskToleranceMaxChanges == null || currConfig?.RiskToleranceMaxChanges == undefined || currConfig?.RiskToleranceMaxChanges === '') ? 100 :  currConfig?.RiskToleranceMaxChanges,
          this.weight = currConfig?.Weight ?? 0;
          this.riskFactor = currConfig?.RiskFactor;

        }

      
      }
      finally {
        this.isLoadUser = false;
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" :is-busy="loading"/>
    <div class="d-flex justify-content-center mt-4" v-if="recalculating">
      <span style="font-size: 3rem;" class=" mt-4 fa fa-spin fa-spinner"></span>
    </div>

    <div>
      <div class="row">
        <div class="col-xl-8">
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <div class="row">
                  <div class="col d-flex justify-content-between">
                      <h5 class="card-title">Performance</h5>
                      <button type="button" class="btn btn-primary" v-if="dateRangeEnabled" @click="resetDateRange">Reset date range to default</button>
                  </div>
                </div>
                
                <highcharts 
                  :constructorType="'stockChart'" 
                  v-if="ready"
                  :key="chartKey"
                  class="hc" 
                  :options="chartOptions" ref="chart">
                </highcharts>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-xl-4">
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body">
                <h5 class="card-title">{{$t('pages.indexView.statistics-title')}}</h5>
                <div class="table-responsive" style="position: relative; " ref="statisticsDiv">
                 
                  <span v-if="!(statistic || {}).FirstTitle">No data found...</span>
                  <table v-if="(statistic || {}).FirstTitle"
                    class="table table-centered table-stripped table-sm datatable table-hover dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th></th>
                        <th><b>Dynamic Hedge</b></th>
                        <th v-if="statistic.HasComparisonValues"><b>Static Hedge</b></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Annualised Return</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).AnnualisedReturn.toString() === '-9999.99' || ((statistic || {}).Values || {}).AnnualisedReturn.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).AnnualisedReturn}}%
                          </span>
                         
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).AnnualisedReturn.toString() === '-9999.99' || (statistic.ComparisonValues || {}).AnnualisedReturn.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}).AnnualisedReturn}}
                          </span>
                        
                        </td>
                      </tr>
                      <tr>
                        <td>Realised Volatility</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).RealisedVolatility.toString() === '-9999.99' || ((statistic || {}).Values || {}).RealisedVolatility.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).RealisedVolatility}}%
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).RealisedVolatility.toString() === '-9999.99' || (statistic.ComparisonValues || {}).RealisedVolatility.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}).RealisedVolatility}}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Downside Volatility</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).DownsideVolatility.toString() === '-9999.99' || ((statistic || {}).Values || {}).DownsideVolatility.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).DownsideVolatility}}%
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).DownsideVolatility.toString() === '-9999.99' || (statistic.ComparisonValues || {}).DownsideVolatility.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}).DownsideVolatility}}
                          </span>
                        </td>
                      </tr>
                    
                      <tr>
                        <td>Winning months</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).WinningMonths.toString() === '-9999.99' || ((statistic || {}).Values || {}).WinningMonths.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).WinningMonths}}%
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).WinningMonths.toString() === '-9999.99' || (statistic.ComparisonValues || {}).WinningMonths.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}).WinningMonths}}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Sharpe Ratio</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).SharpeRatio.toString() === '-9999.99' || ((statistic || {}).Values || {}).SharpeRatio.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).SharpeRatio}}
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).SharpeRatio.toString() === '-9999.99' || (statistic.ComparisonValues || {}).SharpeRatio.toString() === '9999.99'">N/A</span>
                          <span v-else>
                           {{(statistic.ComparisonValues || {}).SharpeRatio}}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Sortino Ratio</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).SortinoRatio.toString() === '-9999.99' || ((statistic || {}).Values || {}).SortinoRatio.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).SortinoRatio}}
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).SortinoRatio.toString() === '-9999.99' || (statistic.ComparisonValues || {}).SortinoRatio.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}).SortinoRatio}}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Calmar Ratio</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).CalmarRatio.toString() === '-9999.99' || ((statistic || {}).Values || {}).CalmarRatio.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}).CalmarRatio}}
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).CalmarRatio.toString() === '-9999.99' || (statistic.ComparisonValues || {}).CalmarRatio.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}).CalmarRatio}}
                          </span>
                        </td>
                      </tr>
                      <tr v-if="isdAdmin">
                        <td>Average Turnover</td>
                        <td>
                          <span v-if="((statistic || {}).Values || {}).AverageTurnover.toString() === '-9999.99' || ((statistic || {}).Values || {}).AverageTurnover.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{((statistic || {}).Values || {}). AverageTurnover}}
                          </span>
                        </td>
                        <td v-if="statistic.HasComparisonValues">
                          <span v-if="(statistic.ComparisonValues || {}).AverageTurnover.toString() === '-9999.99' || (statistic.ComparisonValues || {}).AverageTurnover.toString() === '9999.99'">N/A</span>
                          <span v-else>
                            {{(statistic.ComparisonValues || {}). AverageTurnover}}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="monthlyPerfRowNames && monthlyPerfRowNames.length > 0">
        <div class="col-xl-12" style="" >
          <div class="card cardc8">
            <div class="card-content">
              <div class="card-body" >
                <h5 class="card-title">{{$t('pages.indexView.monthlyPerformance-title')}}</h5>
                <div class="table-responsive">
                  <table
                    class="table table-centered  table-hover nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                    <thead>
                      <tr>
                        <th style="max-width:60px;width:60px; "></th>
                        <th v-for="(cName, $index) in monthNames.filter(x => x !== 'YTD')" :key="$index" style="text-align: right;"><b>{{cName}}</b></th>
                        <th  style="max-width:100px;width:100px;text-align: right; ">{{$t("pages.indexView.monthlyPerformance-ytd")}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(rName, $rIndex) in monthlyPerfRowNames" :key=" $rIndex">
                        <td style="max-width:60px;width:60px; text-align: center;">{{rName}}</td>
                        <td v-for="(cName, $index) in monthNames.filter(x => x !== 'YTD')" :key="$index" style="text-align: right;">
                          <div v-if="fxHedgeData.liveMonthlyPerformanceYear && fxHedgeData.liveMonthlyPerformanceYear <= rName" style="color:#7d096d;font-family: sans-serif;font-weight: 700">
                            {{(monthlyPerf[rName][cName] || {}).base}}
                            <div :style="{'color': secondColor}" v-if="(monthlyPerf[rName][cName] || {}).additional">
                                {{(monthlyPerf[rName][cName] || {}).additional}}
                            </div>
                          </div>
                          <div v-if="!fxHedgeData.liveMonthlyPerformanceYear || fxHedgeData.liveMonthlyPerformanceYear > rName">
                            {{(monthlyPerf[rName][cName] || {}).base}}
                            <div :style="{'color': secondColor}" v-if="(monthlyPerf[rName][cName] || {}).additional">
                                {{(monthlyPerf[rName][cName] || {}).additional}}
                            </div>
                          </div>
                        </td>
                        <td style="max-width:100px;width:100px; text-align: right;">
                          {{(monthlyPerf[rName]['YTD'] || {}).base}}
                          <div :style="{'color': secondColor}" v-if="(monthlyPerf[rName]['YTD'] || {}).additional">
                              {{(monthlyPerf[rName]['YTD'] || {}).additional}}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table> 
                 
                </div>
              </div>
            </div>
          </div>
        </div>   
      </div>  
    
    </div>
  </Layout>
</template>